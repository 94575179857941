import {useParams} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import saveAs from 'file-saver';


import {
    SearchState,
    FilteringState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    IntegratedSelection,
    DataTypeProvider, SelectionState,


} from '@devexpress/dx-react-grid';
import Button from "@mui/material/Button";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
var FileDownload = require('js-file-download');
import {
    Grid,
    Table,
    SearchPanel,
    TableHeaderRow,
    TableRowDetail,
    TableFilterRow,
    VirtualTable,
    DragDropProvider,
    Toolbar,
    PagingPanel,
    TableEditColumn,
    ColumnChooser,
    TableSelection,
    TableColumnVisibility,
    TableColumnResizing, ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
// import Button from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faProjectDiagram, faPencilAlt, faComment} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import {AppContext} from "../../App";

import React, {useState, useEffect, useContext, createContext, useRef, useCallback} from "react";
import axios from "axios";
import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Paper,
    Snackbar
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import TextField from "@mui/material/TextField";
import {GridExporter} from "@devexpress/dx-react-grid-export";

const CustomPaper = (props) => {
    return <Paper sx={{maxHeight:'20vh',overflow:'auto'}} elevation={8} {...props} />;
};
export default function StudentsList(props) {
    const {
        academicYears,
        curCourse,
        email,
        studentCourses,
        courseslist,
        studentDegree,
        degrees,
        tasks
    } = useContext(AppContext);
    const [notesCols] = useState(['notes']);
    const [statusCols] = useState(['status']);
    const [selection, setSelection] = useState([]);
    const [NotesToView,SetNotesToView] = useState([])
    const [OpenNotes,SetOpenNotes] = useState(false)
    const [RowNotes,SetRowNotes] = useState(false)
    const colors ={"enrolled":"black",'completed':'green','withdrawn':'red','expired':'blue'}
    const [OpenRemoveFromGroup,SetOpenRemoveFromGroup] = useState(false)
    const [Description, SetDescription] = useState(false)
    const [Notes,SetNotes] = useState(false)

    const [StudentCourses, SetStudentCourses] = studentCourses
    const [AcademicYears, SetAcademicYears] = academicYears
    const [Error, SetError] = useState(false)
    const [Update, SetUpdate] = useState(false)
    const [Rows, SetRows] = useState([])
    const [OpenComment,SetOpenComment] = useState(false)
    const [NoteType,SetNoteType] = useState('comment')
    const [NoteTypeToView,SetNoteTypeToView] = useState('comment')
    const [Columns, SetColumns] = useState([])
    // const [Columns, SetColumns] = useState([{name: 'name', title: 'Name'},{name: 'description', title: 'Description'},{name: 'members', title: 'Members'}])
    const [CurTask, SetCurTask] = useState(false)
    // const [defaultColumnWidths, setdefaultColumnWidths] = useState([{columnName: 'name', width: 200},{columnName: 'description', width: 200},{columnName: 'members', width: 200}])
    const [defaultColumnWidths, setdefaultColumnWidths] = useState([])
    const {id} = useParams();
    const [Note,SetNote] = useState('')

    const [OpenStatus,SetOpenStatus] = useState(false)
    const [Status,SetStatus] = useState(false)
    const [OpenChangeGroup,SetOpenChangeGroup] = useState(false)

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElTask, setAnchorElTask] = useState(null);
    const [Groups,SetGroups] = useState([])
    const [OpenEval,SetOpenEval] = useState(false)
    const open = Boolean(anchorEl);
    const [deleteCols] = useState(['homework']);
    const [evalCols] = useState(['evaluate_homework']);
    const [evalupCols] = useState(['evaluation']);
    const [CurCourse,SetCurCourse] = curCourse
    const [Score,SetScore] = useState(null)
    const [Comment,SetComment] = useState(null)
    const [RowToEval,SetRowToEval] = useState(false)
    const [Evaluation,SetEvaluation] = useState(false)
    const [NoteStudent,SetNoteStudent] = useState(false)
    const [Acro, SetAcro] = useState(false)
    const [OpenNewGroup, SetOpenNewGroup] = useState(false);

    const [Attachment,SetAttachment] = useState(false)
    const [AttachmentName,SetAttachmentName] = useState(false)

    const [tableColumnVisibilityColumnExtensions] = useState([{
        columnName: 'homework',
        showInColumnChooser: false
    }, {columnName: 'evaluate_homework', hidden: true},{columnName: 'evaluation', hidden: true},{columnName: 'update', hidden: true},
    ]);


    useEffect(()=>{
        if(OpenNotes && NoteStudent && CurCourse){
            axios.get('notes',{params:{studentid:NoteStudent,courseid:CurCourse.id}})
                .then(response=>{SetNotes(response.data['notes']);SetNotesToView(response.data['notes'])})
                .catch(error=>console.log('error',error))
        }
    },[OpenNotes,NoteStudent])

    function submit_group(){
        console.log('new')
        var members = selection.map(i=>Rows[i])
        members = members.map(r=>r.email)
        if(Acro && Description){

            axios.post("group/"+id,{acronym:Acro,description:Description,members:members,action:'create'})

                .then(function (response) {
                    //handle success
                    if(response.data['error']){
                        SetError(response.data['error'])


                    }else{
                        SetOpenChangeGroup(false)
                        SetError('ok, group updated')
                        var rows = Rows.map(r=>r)
                        selection.map(i=>{
                            rows[i].group = Acro
                        })
                        SetRows(rows)
                        SetOpenChangeGroup(false)
                        SetDescription(false)

                        setSelection([])
                        SetAcro(false)
                    }
                })
                .catch(function (response) {
                    SetError("An error occurred during group creation/update")
                });


        }else if (Acro){
            console.log('update')
            axios.post("group/"+id,{acronym:Acro,description:'',members:members,action:'update_group'})

                .then(function (response) {
                    //handle success
                    if(response.data['error']){
                        SetError(response.data['error'])


                    }else{
                        SetOpenChangeGroup(false)
                        SetError('ok, group updated')
                        var rows = Rows.map(r=>r)
                        selection.map(i=>{
                            rows[i].group = Acro
                        })
                        setSelection([])
                        SetOpenChangeGroup(false)
                        SetAcro(false)
                        SetDescription(false)
                        SetRows(rows)
                    }
                })
                .catch(function (response) {
                    SetError("An error occurred during group creation/update")
                });

        }else{
            if(OpenNewGroup){
                SetError('please, set an acronym and a description for the new group')
            }
        }
    }
    const compareStrings = (a, b) => {
        const stringA = a.toLowerCase();
        const stringB = b.toLowerCase();
        if (stringA < stringB) {
            return -1;  // a should come before b
        }
        if (stringA > stringB) {
            return 1;   // a should come after b
        }
        return 0;       // a and b are equal
    };

    const [integratedSortingColumnExtensicons] = useState([
        { columnName: 'group', compare: compareStrings },{ columnName: 'email', compare: compareStrings },{ columnName: 'name', compare: compareStrings },{ columnName: 'surname', compare: compareStrings }
    ]);
    const [FilterExt] = useState([{
        columnName: 'homework',
        filteringEnabled: false,
        SortingEnabled: false
    }, {columnName: 'evaluate_homework', filteringEnabled: false, SortingEnabled: false}, {columnName: 'update', filteringEnabled: false, SortingEnabled: false}, {columnName: 'evaluation', filteringEnabled: false, SortingEnabled: false}])
    const [ResizeExt] = useState([{columnName: 'evaluate_homework', minWidth: 200, maxWidth: 200}])
    const [sortingStateColumnExtensions] = useState([
        {columnName: 'homework', sortingEnabled: false}, {columnName: 'evaluate_homework', sortingEnabled: false},{columnName: 'evaluation', sortingEnabled: false},{columnName: 'update', sortingEnabled: false},
    ]);
    const [pageSizes] = useState([5, 10, 25, 50, 0]);

    const onSave = (workbook) => {
        workbook.csv.writeBuffer().then((buffer) => {
            var filename = 'students_'+CurCourse.acronym+'.csv'
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
        });
    };
    const exporterRef = useRef(null);

    const startExport = useCallback(() => {
        // console.log('export')
        exporterRef.current.exportGrid();
    }, [exporterRef]);




    function handleClose(){
        SetOpenChangeGroup(false)
        SetOpenNotes(false)
        SetNote(false)
        SetNoteType('comment')
        SetNotesToView([])
        setSelection([])
        SetNoteStudent(false)
        SetNoteTypeToView('comment')
        SetAcro(false)
        SetDescription(false)
        SetOpenRemoveFromGroup(false)
        if (document.getElementById('notetype') !== null && document.getElementById('note') !== null  && document.getElementById('notetypefilter') !== null) {

            document.getElementById('notetype').value = ''
            document.getElementById('note').value = ''
            document.getElementById('notetypefilter').value = ''
        }
        if (document.getElementById('groups') !== null && document.getElementById('acro') !== null  && document.getElementById('description') !== null) {

            document.getElementById('groups').value = ''
            document.getElementById('acro').value = ''
            document.getElementById('description').value = ''
        }
    }



    useEffect(() => {
        axios.get("courses",{params:{type:'professor',courseid:id}}).then(response=>{
            var res = response.data['courses'].filter(c => c.is_alias === false)
            SetCurCourse(res[0])

        }).catch(error=>console.log(error))
    }, [])

    useEffect(() => {
        if(CurCourse){
            axios.get('students', {params: {courseid: CurCourse.id}}).then(response => {
                SetRows(response.data['rows'])
                // var groups = response.data['rows'].sort((a, b) => a.group - b.group);
                var groups = response.data['rows'].map(r=>r.group)
                groups = Array.from(new Set(groups.filter(item => item !== '' && item !== 'No group')))
                console.log(groups)
                groups = groups.sort();
                console.log('groupd',groups)
                SetGroups(groups)
                var cols = response.data['cols']
                SetColumns(cols)
            })
        }

    }, [CurCourse])


    useEffect(()=>{
        if(Columns.length > 0){
            var wd = Columns.map(c=>({'columnName':c.name,wordWrapEnabled: true,width:200}))
            setdefaultColumnWidths(wd)
        }
    },[Columns])





    function handleCloseSnack(){
        SetError(false)
    }


    const FilterCell = (props) => {
        const { column } = props;
        if(column.name === 'name' || column.name === 'surname' || column.name === 'status' || column.name === 'email' || column.name === 'group'|| column.name === 'other_courses'){
            return <TableFilterRow.Cell {...props} />;

        }
        else
        {

            return <th className="MuiTableCell-root MuiTableCell-head" style={{borderBottom:'1px solid rgb(224, 224, 224)'}}> </th>
        }

    };

    const StatusFormatter = ({row}) =>
        <div >
            <div>
                <span style={{color:colors[row.status]}}><b>{row.status}</b></span><span><Button size={'small'} onClick={()=> {
                SetOpenStatus(prev => !prev);SetStatus(row.id)
            }}><FontAwesomeIcon icon={faPencilAlt}
                                color='#757575'/></Button></span>
            </div>
            {OpenStatus && Status === row.id && <Autocomplete
                disablePortal
                id={"status_" + row.id}
                value={row.status}
                onChange={(e, newValue) => {
                    e.preventDefault()
                    axios.post('change_status', {studentid: row.id, status: newValue.label, courseid: CurCourse.id})
                        .then(resp => {
                            row.status = newValue.label
                            SetOpenStatus(false)
                        }).catch(error => SetError('an error occurred updating the status'))
                }}
                options={[{label: 'enrolled', id: 'enrolled'},{label: 'expired', id: 'expired'}, {
                    label: 'withdrawn',
                    id: 'withdrawn'
                }, {label: 'completed', id: 'completed'}]}
                sx={{width: '100%',marginTop:'2%'}}
                renderInput={(params) => <TextField {...params} label="Status"/>}
            />}
        </div>


    const StatusProvider = props => (
        <DataTypeProvider
            formatterComponent={StatusFormatter}
            {...props}
        />
    );
    const NotesFormatter = ({row}) =>
        <div>

            <Button className='opt_but' size='sm' onClick={()=>{SetOpenNotes(prev=>!prev);SetNoteStudent(row.id)}}><FontAwesomeIcon icon={faPencilAlt}
                                                                                                                                    color='#757575'/></Button>

        </div>

    const NotesProvider = props => (
        <DataTypeProvider
            formatterComponent={NotesFormatter}
            {...props}
        />
    );



    function submit_note(){
        if(CurCourse && Note && NoteType){
            axios.post('notes',{note:Note,studentid:NoteStudent,type:NoteType,courseid:CurCourse.id})
                .then(response=>{
                    SetOpenNotes(false)
                    handleClose()
                }).catch(error=>SetError('an error occurred publishing the note'))
        }else{
            SetError('Set the note type and the textual note before confirm')
            // SetOpenNotes(false)

        }

    }

    function delete_from_group(){
        var rows = selection.map(i=>Rows[i])
        var members = rows.map(r=>r.email)
        var groups = rows.map(r=>r.group)
        axios.delete('member',{data:{groupid:groups,member:members}})
            .then(response=>SetOpenRemoveFromGroup(false))
            .catch(error=>SetError('An error occurred'))
    }


    return(
        <div>
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />


            <Dialog
                open={OpenRemoveFromGroup && Groups}
                keepMounted
                maxWidth="md"
                fullWidth
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Remove students from group
                </DialogTitle>
                <DialogContent>
                    <div style={{height:'50vh'}}>
                        <div>Selected students:
                            <div style={{margin:'2%'}}>
                                {selection && selection.map(s=>
                                    <div>{Rows[s].email}</div>
                                )}
                            </div>

                        </div>
                        <div>
                            <div>Do you want to remove the student from the related groups? If a student is not in a group this action will have no effect.</div><div>
                            {selection && selection.map(s=>
                                <div><span>{Rows[s].email}: </span><span>{Rows[s].group}</span></div>
                            )}

                        </div>
                        </div>

                    </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button autoFocus onClick={delete_from_group} >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={OpenNotes}
                keepMounted
                maxWidth="lg"
                fullWidth
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Students Notes
                </DialogTitle>
                <DialogContent>
                    <div style={{margin:'2%'}}>
                        {NotesToView && <div style={{margin:'1%',maxHeight:'50vh',overflowY:'scroll'}}>{NotesToView.length} notes found</div>}
                        <div>
                            <Autocomplete
                                disablePortal
                                id="notetypefilter"
                                value={NoteTypeToView}

                                onChange={(e,newvalue)=>{
                                    SetNoteTypeToView(newvalue.id)
                                }}
                                options={[{label:'comment',id:'comment'},{label:'Group Email',id:'groupEmail'},{label:'Student Email',id:'studentEmail'}]}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params} label="Note Type" />}
                            />
                        </div>
                        <div>
                            {NotesToView.map(n=><div style={{margin:'3%'}}>
                                <div style={{marginBottom:'2%'}}>Type: {n.type}</div>
                                <div style={{marginBottom:'2%'}}>Date: {n.last_modified}</div>
                                <div>{n.text}</div>


                                <hr/></div>)}


                        </div>
                        <hr/>
                        <h4>Create new note</h4>
                        <Autocomplete
                            disablePortal
                            id="notetype"
                            onChange={(e,newValue)=>{
                                SetNoteType(newValue.id)
                            }}
                            value={NoteType}
                            options={[{label:'Comment',id:'comment'},{label:'Group Email',id:'groupEmail'},{label:'Student Email',id:'studentEmail'}]}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} label="Type" />}
                        />

                    </div>

                    <div style={{margin:'2%'}}>
                        <TextField
                            sx={{width: '100%', marginBottom: '3%'}}
                            id="note"
                            label="Note"
                            onChange={(e)=>{SetNote(e.target.value)}}

                            multiline
                            rows={5}

                        />
                    </div>






                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button autoFocus onClick={submit_note}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={OpenChangeGroup && Groups}
                keepMounted
                maxWidth="lg"
                fullWidth
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    Update groups
                </DialogTitle>
                <DialogContent><div style={{height:'50vh'}}>
                    <div>Selected students:
                        <div style={{margin:'2%'}}>
                            {selection && selection.map(s=>
                                <div>{Rows[s].email}</div>
                            )}
                        </div>

                    </div>
                    <h5>Assign these students to an existing group</h5>
                    <div>
                        <Autocomplete
                            disablePortal
                            id={"groups"}
                            PaperComponent={CustomPaper}

                            // options={[{label:'comment',id:'comment'},{label:'Group Email',id:'groupEmail'},{label:'Student Email',id:'studentEmail'}]}
                            options={Groups}
                            onChange={(e,newValue)=>{SetAcro(newValue)}}
                            // options={Groups.map(g=>({label:g.name,id:g.id}))}
                            sx={{ width: '100%',margin:'1%' }}
                            renderInput={(params) => <TextField {...params} label="Group" />}
                        />


                    </div><hr/>
                    <h5>Assign these students to a new group</h5>
                    <Button variant={'outlined'} color={'success'} onClick={()=>SetOpenNewGroup(prev=>!prev)}>Create group</Button>
                    {OpenNewGroup && <div>

                        <TextField
                            sx={{width: '100%', margin: '3% 0'}}
                            id="acro"
                            label="Acronym"
                            onChange={(e)=> {
                                SetAcro(e.target.value);
                                document.getElementById('groups').value = ''
                            }}

                        />
                        <TextField
                            sx={{width: '100%', margin: '3% 0'}}
                            id="description"
                            label="Description"
                            onChange={(e)=>SetDescription(e.target.value)}
                            multiline
                            rows={2}

                        />



                    </div>}
                </div>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button autoFocus onClick={submit_group} >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <HeaderBar />
            {CurCourse && <div style={{padding:'1%'}}><a href={window.location.origin+'/courses/'+CurCourse.id}>Back to Courses</a></div>}
            {CurCourse ? <div>
                    <div style={{textAlign:'center',margin:'2%'}}><h1>{CurCourse.name}</h1><h4>Students</h4></div>
                    <div style={{padding: '2%'}}>
                        {selection.length > 0 && <Button sx={{margin:'1%'}}  variant={'contained'} onClick={()=>SetOpenChangeGroup(prev=>!prev)}>Assign to group</Button>}
                        {selection.length > 0 && <Button sx={{margin:'1%'}} variant={'contained'} onClick={()=>SetOpenRemoveFromGroup(prev=>!prev)} color={'error'}>Remove from group</Button>}

                        {Rows && Columns.length > 0 && defaultColumnWidths.length === Columns.length &&
                            <>
                                <Grid
                                    rows={Rows}
                                    columns={Columns}
                                >


                                    <SearchState/>
                                    <PagingState
                                        defaultCurrentPage={0}
                                        defaultPageSize={25}
                                    />
                                    <NotesProvider for={notesCols}/>
                                    <StatusProvider for={statusCols}/>
                                    <FilteringState columnExtensions={FilterExt} defaultFilters={[]}/>
                                    <IntegratedFiltering/>
                                    <SortingState defaultSorting={[{columnName: 'name', direction: 'asc'},]}
                                                  columnExtensions={sortingStateColumnExtensions}
                                    />
                                    <IntegratedSorting columnExtensions={integratedSortingColumnExtensicons} />
                                    <IntegratedPaging/>
                                    <SelectionState
                                        selection={selection}
                                        onSelectionChange={setSelection}
                                    />
                                    <Table />
                                    <TableColumnResizing defaultColumnWidths={defaultColumnWidths}
                                    />

                                    <TableHeaderRow showSortingControls/>
                                    <TableSelection  />

                                    {/*<TableSelection showSelectAll />*/}
                                    <TableFilterRow
                                        cellComponent={FilterCell}
                                    />
                                    <PagingPanel
                                        pageSizes={pageSizes}
                                    />
                                    <TableColumnVisibility
                                        columnExtensions={tableColumnVisibilityColumnExtensions}
                                    />


                                    <Toolbar />

                                    <ExportPanel startExport={startExport}/>

                                </Grid>
                                <GridExporter
                                    ref={exporterRef}
                                    rows={Rows}
                                    columns={Columns}
                                    onSave={onSave}
                                />

                            </>}

                    </div>

                </div> :
                <div className={'circularbar'}><CircularProgress /></div>}
        </div>

    );
}
