import {useParams} from "react-router-dom";
import Switch from '@mui/material/Switch';

import 'bootstrap/dist/css/bootstrap.min.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import {
    SearchState,
    FilteringState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    IntegratedSelection,
    DataTypeProvider,


} from '@devexpress/dx-react-grid';
import Button from "@mui/material/Button";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
var FileDownload = require('js-file-download');
import {
    Grid,
    Table,
    SearchPanel,
    TableHeaderRow,
    TableRowDetail,
    TableFilterRow,
    VirtualTable,
    DragDropProvider,
    TableColumnReordering,
    Toolbar,
    PagingPanel,
    TableEditColumn,
    ColumnChooser,
    TableSelection,
    TableColumnVisibility,
    TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faProjectDiagram, faPencilAlt, faCircle, faCheck} from "@fortawesome/free-solid-svg-icons";
import {AppContext} from "../../App";

import React, {useState, useEffect, useContext, createContext, useRef} from "react";
import axios from "axios";
import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Snackbar
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";

export default function SwitchPub(props) {
    const {
        academicYears,
        curCourse,
        email,
        studentCourses,
        courseslist,
        studentDegree,
        degrees,
        tasks
    } = useContext(AppContext);

    const [OpenStudEvalModal,SetOpenStudEvalModal] = useState(false)
    const [StudentCourses, SetStudentCourses] = studentCourses
    const [AcademicYears, SetAcademicYears] = academicYears
    const [Error, SetError] = useState(false)
    const [Update, SetUpdate] = useState(false)
    const [Rows, SetRows] = useState([])
    const [Tasks,SetTasks] = tasks
    const [OpenScore,SetOpenScore] = useState(false)
    const [ScoreId,SetScoreId] = useState(false)
    const [Columns, SetColumns] = useState([])
    const [CurTask, SetCurTask] = useState(false)
    const [defaultColumnWidths, setdefaultColumnWidths] = useState([])
    const [scoreCols] = useState(['score']);
    const [studCols] = useState(['studeval']);
    const [publishedCol] = useState(['published']);
    const [localScore,setLocalScore] = useState(false);
    const [FormScore,SetFormScore] = useState(false);
    const [OpenSubmitWithFormula,SetOpenSubmitWithFormula] = useState(false)

    const {id} = useParams();

    const compareStrings = (a, b) => {
        const stringA = a.toLowerCase();
        const stringB = b.toLowerCase();
        if (stringA < stringB) {
            return -1;  // a should come before b
        }
        if (stringA > stringB) {
            return 1;   // a should come after b
        }
        return 0;       // a and b are equal
    };

    const [integratedSortingColumnExtensicons] = useState([
        { columnName: 'group', compare: compareStrings },{ columnName: 'email', compare: compareStrings },{ columnName: 'student', compare: compareStrings },
    ]);






    const [SubmitScore,SetSubmitScore] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElTask, setAnchorElTask] = useState(null);
    const [OpenEval,SetOpenEval] = useState(false)
    const open = Boolean(anchorEl);
    const [deleteCols] = useState(['homework']);
    const [evalCols] = useState(['evaluate_homework']);
    const [evalupCols] = useState(['evaluation']);
    const [CurCourse,SetCurCourse] = curCourse
    const [Score,SetScore] = useState(null)
    const [Comment,SetComment] = useState(null)
    const [RowToEval,SetRowToEval] = useState(false)
    const [Evaluation,SetEvaluation] = useState(false)
    const [UpdateP,SetUpdateP] = useState('0')

    const [Attachment,SetAttachment] = useState(false)
    const [AttachmentName,SetAttachmentName] = useState(false)
    const [StudentEvaluations,SetStudentEvaluations] = useState(false)
    const [tableColumnVisibilityColumnExtensions] = useState([{
        columnName: 'homework',
        showInColumnChooser: false
    }, {columnName: 'evaluate_homework', hidden: true},{columnName: 'evaluation', hidden: true},{columnName: 'update', hidden: true},
    ]);


    const [FilterExt] = useState([{
        columnName: 'homework',
        filteringEnabled: false,
        SortingEnabled: false
    }, {columnName: 'evaluate_homework', filteringEnabled: false, SortingEnabled: false}, {columnName: 'update', filteringEnabled: false, SortingEnabled: false}, {columnName: 'evaluation', filteringEnabled: false, SortingEnabled: false}])
    // const [ResizeExt] = useState([{columnName: 'evaluate_homework', minWidth: 200, maxWidth: 200}])
    // const [sortingStateColumnExtensions] = useState([
    //     {columnName: 'homework', sortingEnabled: false}, {columnName: 'evaluate_homework', sortingEnabled: false},{columnName: 'evaluation', sortingEnabled: false},{columnName: 'update', sortingEnabled: false},
    // ]);
    const [pageSizes] = useState([5, 10, 25, 50, 0]);
    const [checked,SetChecked] = useState(false)
    const [Published,SetPublished] = useState(props.row.published === 'Yes' ? true : false)



    return(
        <div>
            <Switch
                id={"switch_" + props.row.id}
                checked={Published}
                onChange={(e) => {
                    if (props.row.published === 'Yes') {
                        console.log('')

                    }else {
                        var studentid = props.row.studentid === '' ? null : props.row.studentid;
                        var taskid = props.taskid
                        var groupid = props.row.groupid === '' ? null : props.row.groupid;
                        axios.post('/publish_results',{taskid:taskid,studentid:studentid,groupid:groupid}).then(response=>{
                            if(response.data['count']> 0){
                                props.row.published = 'Yes'
                                SetPublished(true)
                            }

                            }
                        ).catch(error=>SetError('An error occurred publishing results.'))
                    }
                }}
                inputProps={{'aria-label': 'controlled'}}
            />
        </div>

    );
}
