import './course.css'
import {AppContext} from '../../App';
import React, {useState, useEffect, useContext, createContext} from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap';
import {Container,Row,Col} from "react-bootstrap";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Alert from '@mui/material/Alert'

// import '../General/first_row.css';
import AccountCircle from '@mui/icons-material/AccountCircle';

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Redirect, useParams} from "react-router-dom";
import {
    Autocomplete,
    Chip,
    CircularProgress,
    Collapse, createTheme,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, FormGroup, Paper, Snackbar, withStyles

} from "@mui/material";
import '../../App.css';
import { alpha, styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';



import SearchCourseBar from "./SearchCourseBar";
import HeaderBar from "../utils/headerbar";
import InfoIcon from '@mui/icons-material/Info';
import GroupForm from "../groups/GroupForm2";
import JoinsRequests from "../groups/JoinsRequests";
import TasksList from "../tasks/TasksList";
import Information from "../utils/Information";
import ProfHeaderBar from "../utils/Profheaderbar";
import {cloneDeep} from "lodash";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
function CoursePageProfessor() {

    function createData(name, calories, fat, carbs, protein) {
        return { name, calories, fat, carbs, protein };
    }
    const [Rows,SetRows] = useState(false)
    const { groupinfo,academicYears,studentCourses,email,degree,info,taskinfo,courseslist,studentDegree,degrees,group,curCourse } = useContext(AppContext);

    const [ProfessorCourses,SetProfessorCourses] = studentCourses
    const [AcademicYears,SetAcademicYears] = academicYears
    const [Email,SetEmail] = email
    const [CourseList,SetCourseList] = courseslist
    const [OpenCreate,SetOpenCreate] = useState(false)
    const [CourseToAdd,SetCourseToAdd] = useState(false)
    const [StudentDegree,SetStudentDegree] = studentDegree
    const [Degrees,SetDegrees] = degrees
    const [OpenDialog,SetOpenDialog] = useState(false)
    const [CFU,SetCFU] = useState(false)
    const [Error,SetError] = useState(false)
    const [CurCourse,SetCurCourse] = curCourse
    const [RemoveCourse,SetRemoveCourse] = useState(false)
    const [ModifyCourse,SetModifyCourse] = useState(false)
    const [Redir, SetRedir] = useState(false)
    const [CourseToRemove, SetCourseToRemove] = useState(false)
    const [CourseToMod, SetCourseToMod] = useState(false)
    const { id } = useParams();
    const [HideWithdrawn,SetHideWithdrawn] = useState(false)
    const [HideEnrolled,SetHideEnrolled] = useState(false)

    // aux states
    const [Alias,SetAlias] = useState([])
    const [Credits,SetCredits] = useState([])
    const [EnrollDeadline,SetEnrollDeadline] = useState(false)
    const [GroupDeadline,SetGroupDeadline] = useState(false)



    useEffect(()=>{
        if(CourseToMod){
            SetAlias(CourseToMod.alias.map(a => a.name))
            SetCredits(CourseToMod.cfus)
            SetEnrollDeadline(dayjs(CurCourse.enrolldeadline_txt))
            SetGroupDeadline(dayjs(CurCourse.groupdeadline_txt))
        }else{
            SetAlias([])
            SetCredits([])
            SetEnrollDeadline(false)
            SetGroupDeadline(false)
        }
    },[CourseToMod])
    const styleObj = {
        '&:disabled': {
            color: 'darkgreen',     // Change the text color for disabled state
            border: '1px solid darkgreen',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };
    const styleObj1 = {
        '&:disabled': {
            color: 'darkred',     // Change the text color for disabled state
            border: '1px solid darkred',     // Change the text color for disabled state
            boxShadow: 'none',  // Remove the box shadow for disabled state
        },
    };

    useEffect(()=>{
        if(CourseToAdd){
            if(CourseToAdd.cfus.length === 1){
                SetCFU(CourseToAdd.cfus[0])
            }else{
                SetCFU(false)
            }
        }
    },[CourseToAdd])


    const theme = createTheme({
        root: {

            '&:disabled': {
                background: 'gray', // Change the background color for disabled state
                color: 'darkgreen',     // Change the text color for disabled state
                border: 'darkgreen',     // Change the text color for disabled state
                boxShadow: 'none',  // Remove the box shadow for disabled state
            },
        },
    })

    // useEffect(()=>{
    //
    //
    //     if(!ModifyCourse){
    //         axios.get("courses",{params:{type:'professor'}}).then(response=>{
    //             SetProfessorCourses(response.data['courses'])
    //         }).catch(error=>console.log(error))
    //
    //         axios.get("courses").then(response=>{
    //             SetCourseList(response.data['courses'])
    //         }).catch(error=>console.log(error))
    //
    //         axios.get("degree").then(response=>{
    //             SetDegrees(response.data['degrees'])
    //         }).catch(error=>console.log(error))
    //
    //         axios.get("academic_years").then(response=>{
    //             SetAcademicYears(response.data['academicyears'])
    //         }).catch(error=>console.log(error))
    //     }
    //
    //
    //
    // },[ModifyCourse])

    function normalize(string){
        string = string.toLowerCase().replaceAll(' ', '').replaceAll('null', '')
        return (string !== '' && string !== null && string && string !== undefined)
    }



    const handleClose = () => {
        SetOpenDialog(false)


    };
    useEffect(()=>{
        SetError(false)
    },[StudentDegree,CourseToAdd])

    useEffect(()=>{
        if(!ModifyCourse){
            axios.get("academic_years").then(response=>{
                SetAcademicYears(response.data['academicyears'])
            }).catch(error=>console.log(error))
            axios.get("courses",{params:{type:'professor',courseid:id}}).then(response=>{
                var res = response.data['courses'].filter(c => c.is_alias === false)
                SetProfessorCourses(response.data['courses'])
                SetCurCourse(res[0])

            }).catch(error=>console.log(error))
            axios.get("degree",{params:{courseid:id}}).then(response=>{
                SetRows(response.data['rows'])

            }).catch(error=>console.log(error))
        }





    },[ModifyCourse])

    function modifyCourse(){
        if(CourseToMod){
            if(Credits.length > 0 && CourseToMod.name !== "" && CourseToMod.academic_year !== "" && GroupDeadline && EnrollDeadline){
                console.log(EnrollDeadline.tz("Europe/Berlin").toJSON())
                console.log(EnrollDeadline.format('YYYY-MM-DD').toString())
                var enrolldeadline = EnrollDeadline.format('YYYY-MM-DD').toString()
                var groupdeadline = GroupDeadline.format('YYYY-MM-DD').toString()
                axios.post("/courses",{id:CourseToMod.id,acronym:CourseToMod.acronym,name:CourseToMod.name,academic_year:CourseToMod.academic_year,groupdeadline:groupdeadline,
                    enrolldeadline:enrolldeadline,cfus:Credits,alias:Alias})
                    .then(response=>{
                        if(response.data['error']){
                            SetError(response.data['error'])
                        }else{
                            const updatedList = ProfessorCourses.filter(item => item.id !== CourseToMod.id);
                            SetProfessorCourses([...updatedList,CourseToMod])
                            SetModifyCourse(false) // handle course object
                            SetCourseToMod(false) // handle modal
                        }

                    }).catch(error=>{
                    SetError('an error occurred updating the course.')
                })
            }else{
                SetError('enroll deadline, group deadline, name and cfus must be non empty')
            }

        }
    }

    function removeCourse(){
        if(CourseToRemove){
            axios.delete("/courses",{data:{course:CourseToRemove.id}})
                .then(response=>{
                    if(response.data['error']){
                        SetError(response.data['error'])
                    }else{
                        const updatedList = ProfessorCourses.filter(item => item.id.toString() !== CourseToRemove.id.toString());
                        SetProfessorCourses(updatedList)
                        SetCourseToRemove(false) // handle course object
                        SetRemoveCourse(false) // handle modal
                        SetRedir(true)

                    }

                }).catch(error=>{
                SetError('an error occurred removing the course.')
            })
        }

    }

    function handleCloseRemove(){
        SetRemoveCourse(false)
        SetCourseToRemove(false)
    }
    function handleCloseModify(){
        SetCourseToMod(false)
        SetModifyCourse(false)
    }

    function handleCloseSnack(){
        SetError(false)
    }

    function removeCredits(e,credits){
        e.preventDefault()
        const updatedList = Credits.filter(item => item !== credits);
        SetCredits(updatedList)
        var s = document.getElementById('credits')
        s.value = ''
    }
    function removeAlias(e,alias){
        e.preventDefault()

        const updatedList = Alias.filter(item => item !== alias);
        SetAlias(updatedList)
        var s = document.getElementById('alias')
        s.value = ''

    }






    useEffect(()=>{
        // if(!ModifyCourse){
        //     axios.get("courses",{params:{type:'professor',courseid:id}}).then(response=>{
        //         SetCourseList(response.data['courses'][0])
        //
        //     }).catch(error=>console.log(error))
        // }


        // axios.get('/group',{params:{courseid:id}}).then(response=>{
        //     if('name' in response.data['group']){
        //         SetGroup(response.data['group'])
        //     }
        //
        // })
        // axios.get('/userinfo',{params:{courseid:id}}).then(response=>{
        //     SetName(response.data['name'])
        //     SetSurname(response.data['surname'])
        //     SetDegree(response.data['degree'])
        // })


    },[ModifyCourse])








    function handleCloseSnack(){
        SetError(false)
    }

    return (
        <div>
            {Redir === true && <Redirect to='/my-courses'/>}
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <Dialog
                open={RemoveCourse && CourseToRemove}
                keepMounted
                maxWidth="md"
                fullWidth
                onClose={handleCloseRemove}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Remove the course: "}{CourseToRemove.name}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This action is irreversible and will cause the removal of all the elements correlated to this course such as groups and tasks. Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRemove}>Cancel</Button>
                    <Button onClick={removeCourse}>Ok</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={ModifyCourse && CourseToMod && AcademicYears}
                keepMounted
                maxWidth="lg"
                fullWidth
                sx = {{height:'100vh',overflowY:'scroll'}}
                onClose={handleCloseModify}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Modify course: "}{CourseToMod.name}</DialogTitle>
                <DialogContent>
                    <div>
                        {CourseToMod && <div style={{textAlign:'center',margin:'2%'}}>
                            <Row>
                                {/*<Col md={2}></Col>*/}

                                <Col md={12}>
                                    <div style={{margin:'2%'}}>
                                        <TextField id="name" label="Name" value={CourseToMod.name} sx={{width:'100%',margin:'2%'}}  variant="outlined"
                                                   onChange={(e)=>{
                                                       var name = (e.target.value);
                                                       var course_to_mod = cloneDeep(CourseToMod)
                                                       course_to_mod['name'] = name
                                                       SetCourseToMod(course_to_mod)
                                                   }}/>

                                    </div>
                                    <div style={{margin:'2%'}}>
                                        <TextField id="acro" label="Acronym" value={CourseToMod.acronym} sx={{width:'100%',margin:'2%'}}  variant="outlined"
                                                   onChange={(e)=>{
                                                       var acronym = (e.target.value);
                                                       var course_to_mod = cloneDeep(CourseToMod)
                                                       course_to_mod['acronym'] = acronym
                                                       SetCourseToMod(course_to_mod)
                                                   }}/>

                                    </div>
                                    {AcademicYears && <div style={{margin: '2%'}}>
                                        <Autocomplete
                                            disablePortal
                                            id="ay"
                                            label={'Academic Year'}
                                            options={AcademicYears.map(ay => ({'id': ay.id, 'label': ay.name}))}
                                            value={AcademicYears.map(ay => ({'id': ay.id, 'label': ay.name})).filter(i => i.label === CourseToMod.academic_year )[0]}

                                            sx={{width: '100%', margin: '2%'}}
                                            onChange={(event, newValue) => {

                                                var course_to_mod = cloneDeep(CourseToMod)
                                                if (newValue) {
                                                    course_to_mod['academic_year'] = newValue.label
                                                    SetCourseToMod(course_to_mod)
                                                }else {
                                                    course_to_mod['academic_year'] = ""
                                                    SetCourseToMod(course_to_mod)
                                                }


                                            }}
                                            renderInput={(params) => <TextField {...params} label="Academic year"/>}
                                        />

                                    </div>}
                                    <div style={{margin:'2%'}}>
                                        <div>
                                            <TextField type='number' sx={{width:'100%',margin:'2%'}} id="credits" label="Credits" variant="outlined" />
                                        </div>
                                        <div>
                                            <Button
                                                variant="contained" onClick={(e)=>{
                                                var name = document.getElementById('credits').value;
                                                if(Credits.indexOf(parseInt(name))===-1 && name !== "") {
                                                    SetCredits([...Credits,name])
                                                    var s = document.getElementById('credits')
                                                    s.value = ''
                                                }else {
                                                    SetError('Credits must be non empty and must not be present in the current list of aliases')
                                                }

                                            }}>Add Credits</Button>
                                        </div>
                                        {Credits.length > 0 && <div style={{textAlign: 'left'}}>
                                            <b>Credits:</b>

                                            {Credits.map(c => <div>
                                                <div style={{display: 'inline-block', margin: '1%'}}>{c}</div>
                                                <div style={{display: 'inline-block', margin: '1%'}}><Button
                                                    variant="contained" size='small' color="error"
                                                    onClick={(e) => removeCredits(e, c)}>Remove</Button></div>


                                            </div>)}</div>}
                                    </div>
                                    <div style={{margin:'2%'}}>
                                        <TextField id="alias" sx={{width:'100%',margin:'2%'}}  label="Aliases" variant="outlined" />
                                        <div>
                                            <Button
                                                variant="contained" onClick={(e)=>{
                                                var name = document.getElementById('alias').value;
                                                if(Alias.indexOf(name)===-1 && name !== "") {
                                                    SetAlias([...Alias, name])
                                                }else {
                                                    SetError('Alias must be non empty and must not be present in the current list of aliases')
                                                }

                                                var s = document.getElementById('alias')
                                                s.value = ''
                                            }}>Add Alias</Button>
                                        </div>
                                        {Alias.length >0 && <div style={{textAlign: 'left'}}>
                                            <b>Aliases:</b>
                                            {Alias.map(d => <div>
                                                <div style={{display: 'inline-block', margin: '1%'}}>{d.name ? d.name : d}</div>
                                                <div style={{display: 'inline-block', margin: '1%'}}><Button
                                                    variant="contained" size='small' color="error"
                                                    onClick={(e) => removeAlias(e, d)}>Remove</Button></div>


                                            </div>)}
                                        </div>}

                                    </div>
                                    <div style={{display:'flex',justifyContent:'center'}}>
                                        <div style={{margin:'2%',float:'left'}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    disablePast defaultValue={dayjs(CourseToMod.enrolldeadline_txt)} label="Enrollment deadline" onChange={(newValue) => SetEnrollDeadline(newValue)} />
                                            </LocalizationProvider>
                                        </div>
                                        <div style={{margin:'2%',float:'left'}}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    disablePast defaultValue={dayjs(CourseToMod.groupdeadline_txt)} label="Group creation deadline" onChange={(newValue) => SetGroupDeadline(newValue)}/>
                                            </LocalizationProvider>
                                        </div>
                                    </div>

                                </Col>
                                {/*<Col md={2}></Col>*/}
                            </Row>
                            {/*<div style={{textAlign:"center"}}>*/}

                            {/*    <Button*/}
                            {/*        variant="contained" size = 'large' color="success" onClick={modifyCourse}>Update</Button>*/}

                            {/*</div>*/}


                        </div>}




                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModify}>Disagree</Button>
                    <Button onClick={modifyCourse}>Ok</Button>
                </DialogActions>
            </Dialog>
            <div>
                <HeaderBar/>
                {/*<ProfHeaderBar/>*/}
                {(CurCourse && Email) ? <div style={{textAlign:'center', margin:'2% 0'}}>
                    <h1 style={{margin:'2% 0'}}>{CurCourse.name}</h1>
                    <div style = {{margin:'2% 0'}}> <ProfHeaderBar/></div>


                        <div style={{display:'flex',justifyContent:'center'}}>
                        <Paper sx={{width:'80%',margin:'1%'}} elevation={6}>
                            <div style={{padding:'2%'}}>
                                <Row>
                                    <Col md={1}></Col>
                                    <Col md={10}>
                                        <div>CFU: <b>{CurCourse.cfus.join(', ')}</b></div>
                                        <div>Alias: <b>{CurCourse.alias.map(c => c.name).join(', ')}</b></div>
                                        <div>Students can join the course until: {CurCourse.enrolldeadline_txt}</div>
                                        <div>Students can join and update groups
                                            until: {CurCourse.groupdeadline_txt}</div>
                                        <div>Enrolled students: <b>{CurCourse.enrolled}</b></div>
                                        <div>Completed students: <b>{CurCourse.completed}</b></div>
                                        <div>Expired students: <b>{CurCourse.expired}</b></div>
                                        <div>Withdrawn students: <b>{CurCourse.withdrawn}</b></div>
                                        <div>Groups: <b>{CurCourse.groups}</b></div>
                                        <div>Students with group: <b>{CurCourse.withgroup}</b></div>
                                        <div>Students without group: <b>{CurCourse.nogroup}</b></div>
                                        <hr/>
                                        <div style={{display: 'inline-block', marginLeft: '2%'}}>
                                            <Button size={'small'} variant='contained' color='primary' onClick={(e) => {
                                                e.preventDefault();
                                                SetCourseToMod(CurCourse);
                                                SetModifyCourse(true)
                                            }}>Modify</Button>
                                        </div>
                                        {(CurCourse.enrolled === 0 && CurCourse.withdrawn === 0) &&
                                            <div style={{display: 'inline-block', marginLeft: '2%'}}>
                                                <Button size={'small'} variant='contained' color='error'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            SetCourseToRemove(CurCourse);
                                                            SetRemoveCourse(true)
                                                        }}>Remove</Button>
                                            </div>}
                                    </Col>
                                    <Col md={1}></Col>
                                </Row>
                            </div>


                        </Paper>
                    </div>
                        {Rows && <div style={{display: 'flex', justifyContent: 'center'}}>

                            <Paper sx={{width: '38%', margin: '1%'}} elevation={6}>
                                <h3>Enrolled students</h3>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Degree</b></TableCell>
                                            <TableCell align="right"><b>Count</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Rows['enrolled'].map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.degree}
                                                </TableCell>
                                                <TableCell align="right">{row.count}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </Paper>
                            <Paper sx={{width: '38%', margin: '1%'}} elevation={6}>
                                <h3>Completed students</h3>

                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Degree</b></TableCell>
                                            <TableCell align="right"><b>Count</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Rows['completed'].map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.degree}
                                                </TableCell>
                                                <TableCell align="right">{row.count}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </Paper>
                        </div>}
                        {Rows && <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Paper sx={{width: '38%', margin: '1%'}} elevation={6}>
                                <h3>Expired students</h3>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Degree</b></TableCell>
                                            <TableCell align="right"><b>Count</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Rows['expired'].map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.degree}
                                                </TableCell>
                                                <TableCell align="right">{row.count}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </Paper>
                            <Paper sx={{width: '38%', margin: '1%'}} elevation={6}>
                                <h3>Withdrawn students</h3>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell><b>Degree</b></TableCell>
                                            <TableCell align="right"><b>Count</b></TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Rows['withdrawn'].map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.degree}
                                                </TableCell>
                                                <TableCell align="right">{row.count}</TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>

                            </Paper>

                        </div>}


                </div> :
            <div className={'circularbar'}><CircularProgress /></div>}
        </div>

        </div>
    );

}

export default CoursePageProfessor;
