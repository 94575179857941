import {useParams} from "react-router-dom";
import Switch from '@mui/material/Switch';

import 'bootstrap/dist/css/bootstrap.min.css';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import {
    SearchState,
    FilteringState,
    IntegratedFiltering,
    PagingState,
    IntegratedPaging,
    SortingState,
    IntegratedSorting,
    IntegratedSelection,
    DataTypeProvider,


} from '@devexpress/dx-react-grid';
import Button from "@mui/material/Button";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
var FileDownload = require('js-file-download');
import {
    Grid,
    Table,
    SearchPanel,
    TableHeaderRow,
    TableRowDetail,
    TableFilterRow,
    VirtualTable,
    DragDropProvider,
    TableColumnReordering,
    Toolbar,
    PagingPanel,
    TableEditColumn,
    ColumnChooser,
    TableSelection,
    TableColumnVisibility,
    TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faProjectDiagram, faPencilAlt, faCircle, faCheck} from "@fortawesome/free-solid-svg-icons";
import {AppContext} from "../../App";

import React, {useState, useEffect, useContext, createContext, useRef} from "react";
import axios from "axios";
import {
    Autocomplete,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Snackbar
} from "@mui/material";
import HeaderBar from "../utils/headerbar";
import TextField from "@mui/material/TextField";
import _ from "lodash";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import SwitchPub from "./SwitchPub";

export default function Submissions(props) {
    const {
        academicYears,
        curCourse,
        email,
        studentCourses,
        courseslist,
        studentDegree,
        degrees,
        tasks
    } = useContext(AppContext);

    const [OpenStudEvalModal,SetOpenStudEvalModal] = useState(false)
    const [StudentCourses, SetStudentCourses] = studentCourses
    const [AcademicYears, SetAcademicYears] = academicYears
    const [Error, SetError] = useState(false)
    const [Update, SetUpdate] = useState(false)
    const [Rows, SetRows] = useState([])
    const [Tasks,SetTasks] = tasks
    const [OpenScore,SetOpenScore] = useState(false)
    const [ScoreId,SetScoreId] = useState(false)
    const [Columns, SetColumns] = useState([])
    const [CurTask, SetCurTask] = useState(false)
    const [defaultColumnWidths, setdefaultColumnWidths] = useState([])
    const [scoreCols] = useState(['score']);
    const [studCols] = useState(['studeval']);
    const [publishedCol] = useState(['published']);
    const [localScore,setLocalScore] = useState(false);
    const [FormScore,SetFormScore] = useState(false);
    const [OpenSubmitWithFormula,SetOpenSubmitWithFormula] = useState(false)

    const {id} = useParams();

    const compareStrings = (a, b) => {
        const stringA = a.toLowerCase();
        const stringB = b.toLowerCase();
        if (stringA < stringB) {
            return -1;  // a should come before b
        }
        if (stringA > stringB) {
            return 1;   // a should come after b
        }
        return 0;       // a and b are equal
    };

    const [integratedSortingColumnExtensicons] = useState([
        { columnName: 'group', compare: compareStrings },{ columnName: 'email', compare: compareStrings },{ columnName: 'student', compare: compareStrings },
    ]);






    const [SubmitScore,SetSubmitScore] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElTask, setAnchorElTask] = useState(null);
    const [OpenEval,SetOpenEval] = useState(false)
    const open = Boolean(anchorEl);
    const [deleteCols] = useState(['homework']);
    const [evalCols] = useState(['evaluate_homework']);
    const [evalupCols] = useState(['evaluation']);
    const [CurCourse,SetCurCourse] = curCourse
    const [Score,SetScore] = useState(null)
    const [Comment,SetComment] = useState(null)
    const [RowToEval,SetRowToEval] = useState(false)
    const [Evaluation,SetEvaluation] = useState(false)
    const [UpdateP,SetUpdateP] = useState('0')

    const [Attachment,SetAttachment] = useState(false)
    const [AttachmentName,SetAttachmentName] = useState(false)
    const [StudentEvaluations,SetStudentEvaluations] = useState(false)
    const [tableColumnVisibilityColumnExtensions] = useState([{
            columnName: 'homework',
            showInColumnChooser: false
        }, {columnName: 'evaluate_homework', hidden: true},{columnName: 'evaluation', hidden: true},{columnName: 'update', hidden: true},
    ]);


    const [FilterExt] = useState([{
        columnName: 'homework',
        filteringEnabled: false,
        SortingEnabled: false
    }, {columnName: 'evaluate_homework', filteringEnabled: false, SortingEnabled: false}, {columnName: 'update', filteringEnabled: false, SortingEnabled: false}, {columnName: 'evaluation', filteringEnabled: false, SortingEnabled: false}])
    // const [ResizeExt] = useState([{columnName: 'evaluate_homework', minWidth: 200, maxWidth: 200}])
    // const [sortingStateColumnExtensions] = useState([
    //     {columnName: 'homework', sortingEnabled: false}, {columnName: 'evaluate_homework', sortingEnabled: false},{columnName: 'evaluation', sortingEnabled: false},{columnName: 'update', sortingEnabled: false},
    // ]);
    const [pageSizes] = useState([5, 10, 25, 50, 0]);

    useEffect(() => {
        // if(CurTask){
            var wd_col = []
            wd_col.push({columnName: 'published', width: 200})
            wd_col.push({columnName: 'student', width: 200})
            wd_col.push({columnName: 'email', width: 200})
            wd_col.push({columnName: 'group', width: 200})
            wd_col.push({columnName: 'members', width: 200})
            wd_col.push({columnName: 'score', width: 200})
            wd_col.push({columnName: 'group', width: 200})
            wd_col.push({columnName: 'studeval', width: 200})
            // wd_col.push({columnName: 'cur_state', width: 100})
            wd_col.push({columnName: 'update', width: 200})
            wd_col.push({columnName: 'homework', width: 200})
            wd_col.push({columnName: 'evaluation', width: 200})
            wd_col.push({columnName: 'evaluate_homework', width: 200})
            setdefaultColumnWidths(wd_col)

            var arr_data = []
            var col = []
            if(CurTask){

                if (CurTask.individual === true) {
                    col.push({name: 'student', title: 'Student'})
                    col.push({name: 'email', title: 'Email'})
                    col.push({name: 'group', title: 'Group'})
                }else{
                    col.push({name: 'group', title: 'Group'})
                    col.push({name: 'members', title: 'Members'})
                }





                // col.push({name: 'cur_state', title: 'Current score'})


            if(CurTask.computational === false){
                col.push({name: 'homework', title: 'Homework'})
                col.push({name: 'update', title: 'Last Update'})

            }
            if (CurTask.assessment === true){
                col.push({name: 'studeval', title: 'Students Evaluations'})

            }
            col.push({name: 'score', title: 'Score'})

            col.push({name: 'evaluate_homework', title: 'Evaluate Homework'})
            col.push({name: 'evaluation', title: 'Evaluation Date'})
            col.push({name: 'published', title: 'Published'})
            SetColumns(col)
        }




    }, [CurTask])

    useEffect(() => {
        axios.get('task/'+id.toString(),{params:{info:true}})
            .then(response=> {
                SetCurTask(response.data['tasks'][0]);
                console.log(response.data['tasks'])
            })

            .catch(error=>console.log('error',error))


    }, [])

    useEffect(() => {
        if(CurTask || Update){
            axios.get('get_hws', {params: {taskid: CurTask.id}}).then(response => {
                SetRows(response.data['rows'])
                // var ls = {}
                // response.data['rows'].map(row=>{
                //
                //     if (CurTask.individual){
                //         var id = "score_"+row.studentid
                //         ls[id] = row.score
                //     }else{
                //         var id = "score_"+row.groupid
                //         ls[id] = row.score
                //
                //
                //     }
                // })
                // setLocalScore(ls)
                SetUpdate(false)
            })
        }

    }, [CurTask,Update])

    useEffect(()=>{
        if(Rows && Rows.length >0){
            Rows.map(row=>{
                var el = document.getElementById("score_"+row.id)
                if (el !== null && el !== undefined){
                    el.value = row.score

                }
            })

        }
    },[Rows])

    useEffect(()=>{

        if(ScoreId && SubmitScore && Score){
            let fd = new FormData();
            fd.set('file', Attachment)
            fd.set('taskid', CurTask.id)
            fd.set('groupid', ScoreId.groupid)
            fd.set('studentid', ScoreId.studentid)
            fd.set('score', Score)
            fd.set('comment', Comment)
            fd.set('type', 'inline')



            axios({
                method: "post",
                url: "submit_evaluation",
                data: fd,
                headers: {"Content-Type": "multipart/form-data"},
            }).then(resp => {

                SetSubmitScore(false)
                SetOpenScore(false)
                SetScoreId(false)
                SetScore(false)
                // var el = document.getElementById("score_"+row.id)
                // el.value = newValue

            }).catch(error => SetError('an error occurred updating the score'))
        }
        SetSubmitScore(false)
    },[SubmitScore])

    const ScoreProvider = props => (
        <DataTypeProvider
            formatterComponent={ScoreFormatter}
            {...props}
        />
    );

    const StudEvalFormatter = ({row}) =>
        <div >
            <div>
                <Button size={'small'} onClick={()=> {
                SetOpenStudEvalModal(prev => !prev);SetRowToEval(row)
            }}><FontAwesomeIcon icon={faEye}
                                color='#757575'/></Button>
            </div>



        </div>
    const PublishFormatter = ({row}) =>
        <div >
            <div>
                <SwitchPub row={row} taskid ={CurTask.id} studentid ={CurTask.studentid} groupid={CurTask.groupid}/>
            </div>



        </div>
    const StudEvalProvider = props => (
        <DataTypeProvider
            formatterComponent={StudEvalFormatter}
            {...props}
        />
    );
    const PublishResProvider = props => (
        <DataTypeProvider
            formatterComponent={PublishFormatter}
            {...props}
        />
    );
    useEffect(()=>{

    },[RowToEval])




    const ScoreFormatter = ({row}) =>
        <div >
                <div>
                    <span><b>{row.score}</b></span><span><Button size={'small'} onClick={()=> {
                    SetOpenScore(prev => !prev);SetScoreId(row.id)
                }}><FontAwesomeIcon icon={faPencilAlt}
                                    color='#757575'/></Button></span>
                </div>
                {OpenScore && ScoreId === row.id && <Autocomplete
                    disablePortal
                    id={"score_" + row.id}
                    value={row.score}
                    size={'small'}
                    onChange={(e, newValue) => {
                        e.preventDefault()
                        console.log(newValue)
                        row.score = newValue.label
                        let fd = new FormData();
                        fd.set('file', Attachment)
                        fd.set('taskid', CurTask.id)
                        fd.set('groupid', row.groupid)
                        fd.set('studentid', row.studentid)
                        fd.set('score', newValue.label)
                        fd.set('comment', Comment)
                        fd.set('type', 'inline')
                        SetOpenScore(false)


                        axios({
                            method: "post",
                            url: "submit_evaluation",
                            data: fd,
                            headers: {"Content-Type": "multipart/form-data"},
                        }).then(resp => {

                            SetSubmitScore(false)
                            SetOpenScore(false)
                            SetScoreId(false)


                        }).catch(error => {
                            SetError('an error occurred updating the score');
                            console.log(error)
                        })
                    }}
                    options = {Array.from({ length: 10001 }, (_, index) => ({

                            label: String((index / 100).toFixed(2)),
                            id: String((index / 100).toFixed(2))

                    }))}

                    sx={{width: '100%',marginTop:'2%'}}
                    renderInput={(params) => <TextField {...params} label="Score"/>}
                />}

            {/*<div>*/}
            {/*    <span><b>{row.score}</b></span><span><Button size={'small'} onClick={()=> {*/}
            {/*    SetOpenScore(prev => !prev);SetScoreId(row)*/}
            {/*}}><FontAwesomeIcon icon={faPencilAlt}*/}
            {/*                    color='#757575'/></Button></span>*/}
            {/*</div>*/}
            {/*{OpenScore && ScoreId && ScoreId.id === row.id && <div style={{marginTop:'10px'}}><span><TextField*/}
            {/*    id={row.groupid ? "score_" + row.groupid : "score_" + row.studentid}*/}
            {/*    // value={row.score}*/}
            {/*    sx={{width: '80%',marginTop:'5px'}}*/}
            {/*    label="Score"*/}
            {/*    size={'small'}*/}
            {/*    type={'number'}*/}
            {/*    // value={row.score}*/}
            {/*    onChange={(e)=>{*/}
            {/*        row.score = e.target.value*/}
            {/*    }}*/}

            {/*/></span><span><Button variant={'text'} size={'small'} onClick={()=> {SetSubmitScore(true); SetScore(document.getElementById("score_"+ScoreId.id).value)}}>OK</Button></span></div>}*/}
            {/*    <TextField*/}
            {/*        label="Score"*/}
            {/*        type="number"*/}
            {/*        value={row.groupid ? localScore["score_" + row.groupid] : localScore["score_"+row.studentid]}*/}
            {/*        id={row.groupid ? "score_" + row.groupid : "score_"+row.studentid}*/}
            {/*        onChange={(e) => {*/}
            {/*            var newValue = e.target.value*/}
            {/*            console.log(newValue)*/}
            {/*            // var ls = _.cloneDeep(localScore);*/}
            {/*            // if (CurTask.individual){*/}
            {/*            //     ls["score_"+row.studentid] = newValue*/}
            {/*            // }else{*/}
            {/*            //     ls["score_"+row.groupid] = newValue*/}
            {/*            // }*/}
            {/*            // setLocalScore(ls)*/}
            {/*            // var newValue = e.target.value*/}
            {/*            //*/}
            {/*            if (newValue && parseInt(newValue) >= 0){*/}

            {/*                let fd = new FormData();*/}
            {/*                fd.set('file', Attachment)*/}
            {/*                fd.set('taskid', CurTask.id)*/}
            {/*                fd.set('groupid', row.groupid)*/}
            {/*                fd.set('studentid', row.studentid)*/}
            {/*                fd.set('score', newValue)*/}
            {/*                fd.set('comment', Comment)*/}

            {/*                axios({*/}
            {/*                    method: "post",*/}
            {/*                    url: "submit_evaluation",*/}
            {/*                    data: fd,*/}
            {/*                    headers: {"Content-Type": "multipart/form-data"},*/}
            {/*                }).then(resp => {*/}
            {/*                        var ls = _.cloneDeep(localScore);*/}
            {/*                        if (CurTask.individual){*/}
            {/*                            ls["score_"+row.studentid] = newValue*/}
            {/*                        }else{*/}
            {/*                            ls["score_"+row.groupid] = newValue*/}
            {/*                        }*/}
            {/*                        setLocalScore(ls)*/}

            {/*                    }).catch(error => SetError('an error occurred updating the score'))*/}
            {/*            }*/}


            {/*        }*/}

            {/*        sx={{width: '100%',marginTop:'5px'}}*/}
            {/*/>*/}
        </div>




    function submit_evaluation(e){
        e.preventDefault()

        if (Score <0){
            SetError('At least a score is requried.')
        }else {


            let fd = new FormData();
            fd.set('file', Attachment)
            fd.set('taskid', id)
            fd.set('groupid', RowToEval.groupid)
            fd.set('studentid', RowToEval.studentid)
            fd.set('score', Score)
            fd.set('comment', Comment)
            axios({
                method: "post",
                url: "submit_evaluation",
                data: fd,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(response => {
                    var id_t = RowToEval.groupid ? "score_" + RowToEval.groupid : "score_"+RowToEval.studentid
                    var inp = document.getElementById(id_t)
                    if (inp !== undefined && inp !== null){
                        inp.value = Score

                    }
                    handleClose()
                    // SetEvaluation(false)
                    // SetRowToEval(false)
                    // SetAttachment(false)
                    // SetAttachmentName(false)
                    // SetScore(null)
                    // SetUpdate(true)
                    // SetComment(null)
                    // SetOpenEval(false)
                    // SetError(false)
                })
                .catch(error => {
                        console.log(error)
                        SetError('An error occurred saving evaluation')
                    }
                )
        }

    }
    function downloadEvaluation(e,row){
        e.preventDefault()
        if(Attachment){
            FileDownload(Attachment,AttachmentName);

        }else{
            axios.get('/download_eval',{params:{taskid:CurTask.id,groupid:row.groupid,studentid:row.studentid},responseType: 'blob'})
                .then(response => {
                    // Creating the blob file and its url
                    var blob = new Blob([response.data]);
                    let url = window.URL.createObjectURL(blob);


                    // Creating the hyperlink and auto click it to start the download
                    let link = document.createElement('a');
                    link.href = url;
                    link.download = AttachmentName
                    link.click();
                    //window.location.href = response.url;
                });
        }

        console.log('download eval')
    }
    function downloadHW(e,row) {
        e.preventDefault()
        var json_params = {}


        axios.get('/download_file',{params:{taskid:CurTask.id,groupid:row.groupid,studentid:row.studentid},responseType: 'blob'})
            .then(response => {
                // Creating the blob file and its url
                var blob = new Blob([response.data]);
                let url = window.URL.createObjectURL(blob);


                // Creating the hyperlink and auto click it to start the download
                let link = document.createElement('a');
                link.href = url;
                link.download = row.homework
                link.click();
                //window.location.href = response.url;
            });
    }

    const EvalUpdateTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={EvalUpdateFormatter}
            {...props}
        />
    );
    const DownloadTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={DownloadFormatter}
            {...props}
        />
    );
    const EvalTypeProvider = props => (
        <DataTypeProvider
            formatterComponent={EvalFormatter}
            {...props}
        />
    );

    useEffect(()=>{
        if(RowToEval){
            axios.get('get_eval',{params: {
                    studentid: RowToEval.studentid,
                    groupid: RowToEval.groupid,
                    taskid: CurTask.id
                }}).then(response=>{
                    SetEvaluation(response.data);
                    SetAttachmentName(response.data.attachment)
                    SetScore(response.data.score)
                    SetComment(response.data.comment)
            }).catch(error=>console.log(error))

        }
    },[RowToEval])

    function handleCloseSnack(){
        SetError(false)
    }

    const DownloadFormatter = ({row}) =>
        <div>

            {row.homework ? <Button className='opt_but' size='sm' onClick={(e) => downloadHW(e, row)}>{row.homework}</Button> : <div></div>}

        </div>

    const EvalUpdateFormatter = ({row}) =>
        <div>

           <div>{row.evaluation}</div>

        </div>
    const EvalFormatter = ({row}) =>
        <div>
            <Button className='opt_but' size='sm' onClick={()=> {
                SetOpenEval(prev => !prev);
                SetRowToEval(row)
            }}><FontAwesomeIcon icon={faPencilAlt}
                                                                                                       color='#757575'/></Button>

        </div>
    
    const FilterCell = (props) => {
        const { column } = props;
        if(column.name === 'group' || column.name === 'student' || column.name === 'email' || column.name === 'cur_state' || column.name === 'members'){
            return <TableFilterRow.Cell {...props} />;

        }
        else
        {

            return <th className="MuiTableCell-root MuiTableCell-head" style={{borderBottom:'1px solid rgb(224, 224, 224)'}}> </th>
        }

    };



    useEffect(()=>{
        if(OpenStudEvalModal && RowToEval && CurTask ){
            axios.get("get_students_evaluations",{params:{courseid:CurTask.courseid,evaluated:RowToEval.id}})
                .then(response=>{
                    SetStudentEvaluations(response.data['evaluations'])
                }).catch(error=> {
                console.log(error);
                SetError("an error occurred with the evaluations")
            })
        }
    },[OpenStudEvalModal,RowToEval])
    const handleClose = () => {
        SetOpenEval(false);
        SetEvaluation(false)
        SetRowToEval(false)
        SetAttachment(false)
        SetAttachmentName(false)
        SetScore(null)
        SetComment(null)
        SetStudentEvaluations(false)
        SetOpenStudEvalModal(false)
    };

    function submit_with_formula(e){
        e.preventDefault()
        axios.post("calibrate",{task:CurTask.id})
            .then(response=>{
                Object.entries(response.data).map(st =>{
                    var row = Rows.find(({ id }) => id.toString() === parseInt(st[0]).toString());
                    row.score = st[1]
                })
                SetOpenSubmitWithFormula(false)

            }).catch(error=> {
                console.log(error);
                SetError('An error occurred computing the scores')
            }
            )
    }

    function handleCloseSubmitWithFormula(){
        SetOpenSubmitWithFormula(false)
    }
    return(
        <div>
            <Snackbar
                open={Error}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
                message={Error}
            />
            <Dialog
                open={OpenSubmitWithFormula}
                keepMounted
                maxWidth="md"
                fullWidth
                onClose={handleCloseSubmitWithFormula}
            >
                <DialogTitle id="alert-dialog-title">
                    Compute scores with selected expressions?
                </DialogTitle>
                <DialogContent>
                    All the socres will be computed with the expresssion(s) you added. If one or more students have ad-hoc expressions these will be used, otherwise "default" formula will be applied.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSubmitWithFormula}>Cancel</Button>
                    <Button autoFocus onClick={submit_with_formula}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={OpenEval && Evaluation}
                keepMounted
                maxWidth="md"
                fullWidth
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                   Evaluate {CurTask.individual ? RowToEval.student : RowToEval.group}
                </DialogTitle>
                <DialogContent>
                    {Evaluation && <div style={{padding: '2%'}}>
                        <TextField
                            sx={{width: '100%', marginBottom: '3%'}}
                            id="score"
                            value={Score}
                            onChange={(e)=>{SetScore(e.target.value)}}
                            label="Score"
                            type="number"

                        />
                        <TextField
                            sx={{width: '100%', marginBottom: '3%'}}
                            id="comment"
                            label="Comment"
                            onChange={(e)=>{SetComment(e.target.value)}}

                            value={Comment}
                            multiline
                            rows={10}

                        />
                        <div>
                            <h4>Upload comment</h4>
                            <b>Current comment:</b>
                            {AttachmentName && <Button className='opt_but' size='sm'
                                     onClick={(e) => downloadEvaluation(e, RowToEval)}>{AttachmentName}</Button>}

                            {AttachmentName && <Button sx={{marginLeft: '1%', display:'inline-block'}} color='error' onClick={() => {
                                SetAttachment(false);
                                SetAttachmentName(false)
                            }}>Delete</Button>}

                            <div style={{margin:'2%'}}>
                                <Button component="label"
                                        disabled={!RowToEval}
                                        variant={'outlined'} size={'small'} color={'primary'}>
                                    {/*<VisuallyHiddenInput type="file" />*/}
                                    <input
                                        id={'hw_upload_' + RowToEval.studentid.toString() + '_' + RowToEval.groupid.toString()}
                                        type="file"
                                        onChange={(e) => {

                                            // SetInpuLength(input.files.length)
                                            var files = []
                                            if (e.target.files[0] !== undefined || e.target.files[0] !== null) {
                                                for (let ind = 0; ind < e.target.files.length; ind++) {
                                                    files.push(e.target.files[ind])
                                                }
                                            }


                                            SetAttachment(files[0])
                                            SetAttachmentName(files[0].name)

                                        }}
                                        hidden
                                    />
                                    Upload
                                </Button>

                            </div>

                        </div>
                    </div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button autoFocus onClick={submit_evaluation}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={OpenStudEvalModal && StudentEvaluations}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Student Evaluations for: {RowToEval.student}
                </DialogTitle>
                <DialogContent>
                    <div>
                        {StudentEvaluations && StudentEvaluations.map(e=>
                            <div style={{margin:'2%'}}>
                                <div>
                                    <b>Evaluator: </b><span>{e['evaluator']}</span>
                                </div>
                                <div>
                                    <b>Score: </b><span>{e['score']}</span>
                                </div>
                                <div>
                                    <b>Comment: </b><span>{e['comment']}</span>
                                </div>
                                <hr/>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>

                </DialogActions>
            </Dialog>



            <HeaderBar />
            {CurTask && <div style={{padding:'1%'}}><a href={window.location.origin+'/task/'+CurTask.courseid}>Back to Tasks</a></div>}
            {(CurTask && Columns.length > 0) ? <div>
                <div style={{textAlign:'center',margin:'2%'}}><h1>Evaluate {CurTask.name}</h1></div>
                {/*{OpenInfoModal && CurID && CurAnnotation && <InfoModal show={OpenInfoModal} setshow={SetOpenInfoModal} curid={CurID} annotation={CurAnnotation}  row={props.righe} />}*/}
                {/*{OpenContentModal && Content && <ContentModal show={OpenContentModal} setshow={SetOpenContentModal} content={Content} />}*/}
                {/*{OpenDeleteModal && IdToDel && <DeleteDocumentModal show={OpenDeleteModal} setshow={SetOpenDeleteModal} doc={IdToDel} setconfirm={SetConfirmDelete}/>}*/}
                {/*{OpenDownloadModal && IdToDownload && <DownloadModal show={OpenDownloadModal} setshow={SetOpenDownloadModal} doc={IdToDownload}/>}*/}
                <div style={{padding: '2%'}}>
                    {CurTask.evalfnjson !== undefined && CurTask.evalfnjson !== null && <Button onClick={()=>SetOpenSubmitWithFormula(prev=>!prev)} >Submit with scoring formula</Button>}
                    <Grid
                        rows={Rows}
                        columns={Columns}
                    >

                        <DownloadTypeProvider for={deleteCols}/>
                        <EvalTypeProvider for={evalCols}/>
                        <EvalUpdateTypeProvider for={evalupCols}/>
                        {/*<AnnotationStatsProvider for={['mentions_count','concepts_count','relationships_count','labels_count','assertions_count','annotators_list']} />*/}

                        <SearchState/>
                        <PagingState
                            defaultCurrentPage={0}
                            defaultPageSize={25}
                        />

                        <ScoreProvider for={scoreCols}/>
                        <StudEvalProvider for={studCols}/>
                        <PublishResProvider for={publishedCol}/>
                        <FilteringState columnExtensions={FilterExt} defaultFilters={[]}/>
                        <IntegratedFiltering/>
                        <SortingState
                            defaultSorting={[{ columnName: 'group', direction: 'asc' },{columnName: 'student', direction: 'asc'}]}
                        />
                        <IntegratedSorting columnExtensions={integratedSortingColumnExtensicons} />
                        <IntegratedPaging/>
                        <Table/>
                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths}
                                             />
                        <TableHeaderRow showSortingControls />
                        {/*<TableSelection showSelectAll />*/}
                        <TableFilterRow
                            cellComponent={FilterCell}
                        />
                        <PagingPanel
                            pageSizes={pageSizes}
                        />
                        <TableColumnVisibility
                            columnExtensions={tableColumnVisibilityColumnExtensions}
                        />

                    </Grid>
                </div>

            </div> :
                <div className={'circularbar'}><CircularProgress /></div>}
        </div>

    );
}
